.banner {
  /* background-image: url(beer-can-1462978185rtV.webp); */
  background-image: url(../src/img/NYC2.jpg);
  /* background-image: url(https://codingcat.codes/wp-content/uploads/2017/08/l1-1.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  height: 40rem;
}

.localesbanner {
  background-image: url(../src/img/Local1.jpeg);
  /* background-position: center center; */
  background-position: 0% 60%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  height: 20rem;
}

.casasbanner {
  background-image: url(../src/img/home.jpg);
  /* background-position: center center; */
  background-position: 0% 80%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  height: 20rem;
}

.terrabanner {
  background-image: url(../src/img/terra.jpg);
  /* background-position: center center; */
  background-position: 0% 80%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  height: 20rem;
}

.itemCard {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 3s;
}
.itemCard:hover {
  border: 1px black outset;
}

.mask-dark {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.311);
}
.transparent {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0px rgba(0, 0, 0, 0) !important;
}
.jcc {
  display: flex;
  justify-content: center;
}

.alc {
  display: flex;
  align-items: center;
}

.fdc {
  display: flex;
  flex-direction: column;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.lift:hover {
  transition: all 0.3s;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.37);
  transform: translateY(-2%);
  -o-transition: translateY(-2%); /* opera */
  -ms-transition: translateY(-2%); /* IE 10 */
  -moz-transition: translateY(-2%); /* Firefox */
  -webkit-transition: translateY(-2%); /*safari and chrome */
}

.text-footer {
  font-size: 0.9rem;
}

.logo {
  height: 10rem;
  width: auto;
}

.footer {
  background-color: rgb(24, 24, 24);
}
