.banner {
  background-image: url(../src/img/NYC.jpg);
  background-position: center;
  background-attachment: scroll !important;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 55rem;
  width: 100vw;
}

@media (min-width: 1000px) {
  .banner {
    background-image: url(../src/img/NYC.jpg);
    background-position: center;
    background-attachment: fixed !important;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: 100% auto;
    background-repeat: no-repeat;
    height: 55rem;
    width: 100vw;
  }
}

.img1 {
  height: auto;
  width: 100%;
}
.mask-dark {
  height: 100%;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.411);
}

.jcc {
  display: flex;
  justify-content: center;
}

.alc {
  display: flex;
  align-items: center;
}

.fdc {
  display: flex;
  flex-direction: column;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.lift:hover {
  transition: all 0.3s;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.37);
  transform: translateY(-2%);
  -o-transition: translateY(-2%); /* opera */
  -ms-transition: translateY(-2%); /* IE 10 */
  -moz-transition: translateY(-2%); /* Firefox */
  -webkit-transition: translateY(-2%); /*safari and chrome */
}

.text-footer {
  font-size: 0.9rem;
}
.text-banner {
  display: inline;
}

.logo {
  height: 10rem;
  width: auto;
}

.footer {
  background-color: rgb(24, 24, 24);
}

.navBrand {
  text-decoration: none;
  /* color: black; */
}

/* .navBrand:hover {
  text-decoration: none;
  color: black;
} */

.spacing {
  height: 5rem;
}
.navLink {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(22, 22, 22, 0.856);
}

.navLink:hover {
  text-decoration: none;
  color: black;
}
#introLoc {
  height: 15rem;
  background-color: rgb(10, 10, 161);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.imgCo {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.spb {
  justify-content: space-between;
}
